<template>
    <div class="app-admin-page application-page">
        <ApplicationHeader name="appType.list" :count="count" add @click="onFilterClick"/>
        <ApplicationContent v-bind="contentAttrs" v-on="contentListeners" sekeleton :skeletonList="skeletonList"/>
        <ApplicationModal v-if="modalVisible" v-bind="modalAttributes" v-on="modalListeners" icon="option-add" name="appType.add" ref="modal"/>
    </div>
</template>
<script>
import ApplicationHeader from "../../components/admin/ApplicationHeader";
import ApplicationContent from "../../components/admin/ApplicationContent";
import ApplicationModal from "../../components/modals/ApplicationModal";
import {CONTENT_ITEMS_SIZES} from "../../components/admin/ContentItems";
import {
    ContentLoader,
    FormInputElement,
    FormInputList, ModalController,
    ModalFormController,
    ScriptPage
} from "../../services/ScriptPage";
import {getApplicationDefaultMixin, getApplicationModalMixin} from "../../mixins/ScriptPage";

class MarketingContentLoader extends ContentLoader {
    constructor() {
        super('/app-types');
    }
    fetch(filterStr) {
        return super.fetch(filterStr)
            .then(f => {
                return {
                    data: f.response.data,
                    count: f.response.data.length,
                    success: f.response.success
                }
            })
    }
}

export default {
    name: "AppType",
    components: {
        ApplicationModal,
        ApplicationContent,
        ApplicationHeader
    },
    mixins: [getApplicationDefaultMixin(
        new ScriptPage(
            new MarketingContentLoader(),
            {},
            new ModalFormController(
                new FormInputList('1', '', '', '', [
                    new FormInputElement('id', 'common.form.id', '', 'hidden'),
                    new FormInputElement('title', 'common.table.nameLong', '', 'input', null, [['required']]),
                ])
            ),
            new ModalController()
        )),
        getApplicationModalMixin()
    ],
    methods: {
        parseItemData(item, idx) {
            return [
                {
                    name: 'common.table.number',
                    value: (idx + 1),
                    size: CONTENT_ITEMS_SIZES[0],
                    skeleton: this.loading,
                    skeletonHeight: "20px"
                },
                {
                    name: 'common.table.name',
                    value: item.title,
                    size: CONTENT_ITEMS_SIZES[3],
                    skeleton: this.loading,
                    skeletonHeight: "20px"
                },
            ]
        }
    },

    computed: {
        skeletonList() {
            const list = []
            for (let index = 0; index < 10; index++) {
                list.push({
                    id: index,
                    skeleton: true,
                })
            }
            return list
        }
    }
}
</script>
